var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:['user-layout-wrapper', _vm.device],attrs:{"id":"userLayout"}},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"main"},[_c('a-form',{ref:"formLogin",staticClass:"user-layout-login",attrs:{"id":"formLogin","form":_vm.form},on:{"submit":_vm.handleSubmit}},[_c('a-tabs',{attrs:{"activeKey":_vm.customActiveKey,"tabBarStyle":{ textAlign: 'center', borderBottom: 'unset' }},on:{"change":_vm.handleTabClick}},[_c('a-tab-pane',{key:"tab1",attrs:{"tab":"密码登陆"}},[_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                  'username',
                  {
                    rules: [{ required: true, message: '请输入手机号' }],
                    validateTrigger: 'change'
                  }
                ]),expression:"[\n                  'username',\n                  {\n                    rules: [{ required: true, message: '请输入手机号' }],\n                    validateTrigger: 'change'\n                  }\n                ]"}],attrs:{"size":"large","type":"text","placeholder":"请输入手机号"}},[_c('a-icon',{style:({ color: 'rgba(0,0,0,.25)' }),attrs:{"slot":"prefix","type":"user"},slot:"prefix"})],1)],1),_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                  'password',
                  {
                    rules: [{ required: true, message: '请输入密码' }],
                    validateTrigger: 'blur'
                  }
                ]),expression:"[\n                  'password',\n                  {\n                    rules: [{ required: true, message: '请输入密码' }],\n                    validateTrigger: 'blur'\n                  }\n                ]"}],attrs:{"size":"large","type":"password","autocomplete":"false","placeholder":"请输入密码"}},[_c('a-icon',{style:({ color: 'rgba(0,0,0,.25)' }),attrs:{"slot":"prefix","type":"lock"},slot:"prefix"})],1)],1)],1),_c('a-tab-pane',{key:"tab2",attrs:{"tab":"手机号登录"}},[_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                  'username',
                  {
                    rules: [
                      {
                        required: true,
                        pattern: /^1[3456789]\d{9}$/,
                        message: '请输入正确的手机号'
                      }
                    ],
                    validateTrigger: 'change'
                  }
                ]),expression:"[\n                  'username',\n                  {\n                    rules: [\n                      {\n                        required: true,\n                        pattern: /^1[3456789]\\d{9}$/,\n                        message: '请输入正确的手机号'\n                      }\n                    ],\n                    validateTrigger: 'change'\n                  }\n                ]"}],attrs:{"size":"large","type":"text","placeholder":"手机号"}},[_c('a-icon',{style:({ color: 'rgba(0,0,0,.25)' }),attrs:{"slot":"prefix","type":"mobile"},slot:"prefix"})],1)],1),_c('a-row',{attrs:{"gutter":16}},[_c('a-col',{staticClass:"gutter-row",attrs:{"span":16}},[_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                      'code',
                      {
                        rules: [{ required: true, message: '请输入验证码' }],
                        validateTrigger: 'blur'
                      }
                    ]),expression:"[\n                      'code',\n                      {\n                        rules: [{ required: true, message: '请输入验证码' }],\n                        validateTrigger: 'blur'\n                      }\n                    ]"}],attrs:{"size":"large","type":"text","placeholder":"验证码"}},[_c('a-icon',{style:({ color: 'rgba(0,0,0,.25)' }),attrs:{"slot":"prefix","type":"mail"},slot:"prefix"})],1)],1)],1),_c('a-col',{staticClass:"gutter-row",attrs:{"span":8}},[_c('a-button',{staticClass:"getCaptcha",attrs:{"tabindex":"-1","disabled":_vm.state.smsSendBtn},domProps:{"textContent":_vm._s(
                    (!_vm.state.smsSendBtn && '获取验证码') || _vm.state.time + ' s'
                  )},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.getCode.apply(null, arguments)}}})],1)],1)],1)],1),_c('a-form-item',{staticStyle:{"margin-top":"24px"}},[_c('a-button',{staticClass:"login-button",attrs:{"size":"large","type":"primary","htmlType":"submit","loading":_vm.state.loginBtn,"disabled":_vm.state.loginBtn}},[_vm._v("确定")])],1)],1)],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }