<template>
  <div id="userLayout" :class="['user-layout-wrapper', device]">
    <div class="container">
      <!-- <div class="top">
        <div class="header">
          <span class="title">授权验证</span>
        </div>
        <div class="desc">
          &nbsp;
        </div>
      </div> -->

      <div class="main">
        <a-form
                id="formLogin"
                class="user-layout-login"
                ref="formLogin"
                :form="form"
                @submit="handleSubmit"
        >
          <a-tabs
                  :activeKey="customActiveKey"
                  :tabBarStyle="{ textAlign: 'center', borderBottom: 'unset' }"
                  @change="handleTabClick"
          >
            <!-- <a-tab-pane key="tab1" tab="登录">-->
            <a-tab-pane key="tab1" tab="密码登陆">
              <a-form-item>
                <a-input
                        size="large"
                        type="text"
                        placeholder="请输入手机号"
                        v-decorator="[
                    'username',
                    {
                      rules: [{ required: true, message: '请输入手机号' }],
                      validateTrigger: 'change'
                    }
                  ]"
                >
                  <a-icon
                          slot="prefix"
                          type="user"
                          :style="{ color: 'rgba(0,0,0,.25)' }"
                  />
                </a-input>
              </a-form-item>

              <a-form-item>
                <a-input
                        size="large"
                        type="password"
                        autocomplete="false"
                        placeholder="请输入密码"
                        v-decorator="[
                    'password',
                    {
                      rules: [{ required: true, message: '请输入密码' }],
                      validateTrigger: 'blur'
                    }
                  ]"
                >
                  <a-icon
                          slot="prefix"
                          type="lock"
                          :style="{ color: 'rgba(0,0,0,.25)' }"
                  />
                </a-input>
              </a-form-item>
            </a-tab-pane>
            <a-tab-pane key="tab2" tab="手机号登录">
              <a-form-item>
                <a-input
                        size="large"
                        type="text"
                        placeholder="手机号"
                        v-decorator="[
                    'username',
                    {
                      rules: [
                        {
                          required: true,
                          pattern: /^1[3456789]\d{9}$/,
                          message: '请输入正确的手机号'
                        }
                      ],
                      validateTrigger: 'change'
                    }
                  ]"
                >
                  <a-icon
                          slot="prefix"
                          type="mobile"
                          :style="{ color: 'rgba(0,0,0,.25)' }"
                  />
                </a-input>
              </a-form-item>

              <a-row :gutter="16">
                <a-col class="gutter-row" :span="16">
                  <a-form-item>
                    <a-input
                            size="large"
                            type="text"
                            placeholder="验证码"
                            v-decorator="[
                        'code',
                        {
                          rules: [{ required: true, message: '请输入验证码' }],
                          validateTrigger: 'blur'
                        }
                      ]"
                    >
                      <a-icon
                              slot="prefix"
                              type="mail"
                              :style="{ color: 'rgba(0,0,0,.25)' }"
                      />
                    </a-input>
                  </a-form-item>
                </a-col>
                <a-col class="gutter-row" :span="8">
                  <a-button
                          class="getCaptcha"
                          tabindex="-1"
                          :disabled="state.smsSendBtn"
                          @click.stop.prevent="getCode"
                          v-text="
                      (!state.smsSendBtn && '获取验证码') || state.time + ' s'
                    "
                  ></a-button>
                </a-col>
              </a-row>
            </a-tab-pane>
          </a-tabs>

          <a-form-item style="margin-top:24px">
            <a-button
                    size="large"
                    type="primary"
                    htmlType="submit"
                    class="login-button"
                    :loading="state.loginBtn"
                    :disabled="state.loginBtn"
            >确定</a-button
            >
          </a-form-item>
        </a-form>
      </div>
    </div>
  </div>
</template>

<script>
  import TwoStepCaptcha from '@/components/tools/TwoStepCaptcha';
  import { sipSms, sipLogin, storeInsert } from '@api';
  import { ACCESS_TOKEN } from '@/store/mutation-types';
  import Vue from 'vue';
  import axios from 'axios';

  export default {
    components: {
      TwoStepCaptcha
    },
    data() {
      return {
        customActiveKey: 'tab1',
        loginBtn: false,
        // login type: 0 email, 1 username, 2 telephone
        loginType: 0,
        requiredTwoStepCaptcha: false,
        stepCaptchaVisible: false,
        form: this.$form.createForm(this),
        state: {
          time: 60,
          loginBtn: false,
          // login type: 0 email, 1 username, 2 telephone
          loginType: 0,
          smsSendBtn: false
        }
      };
    },
    created() {
      const token = Vue.ls.get(ACCESS_TOKEN)
      if (token ) {
        this.handleStoreInsert(token);
      }
    },
    methods: {
      handleTabClick(key) {
        this.customActiveKey = key;
      },
      handleSubmit(e) {
        e.preventDefault();
        const {
          form: { validateFields },
          state,
          customActiveKey
        } = this;
        state.loginBtn = true;

        const validateFieldsKey =
                customActiveKey === 'tab1'
                        ? ['username', 'password']
                        : ['username', 'code'];
        validateFields(validateFieldsKey, { force: true }, (err, values) => {
          if (!err) {
            if (customActiveKey == 'tab1') {
              // 用户名密码
            } else if (customActiveKey == 'tab2') {
              // 手机号验证码
            }
            sipLogin(values)
                    .then((res) => {
                      if (res.success) {
                        const data = res.data;
                        const token = data.token;
                        this.handleStoreInsert(token);
                      } else {
                        this.requestFailed(res);
                      }
                    })
                    .catch((err) => this.requestFailed(err))
                    .finally(() => {
                      state.loginBtn = false;
                    });
          } else {
            setTimeout(() => {
              state.loginBtn = false;
            }, 600);
          }
        });
      },
      getCode(e) {
        e.preventDefault();
        const {
          form: { validateFields },
          state,
          $message,
          $notification
        } = this;
        validateFields(['username'], { force: true }, (err, values) => {
          if (!err) {
            state.smsSendBtn = true;
            const interval = window.setInterval(() => {
              if (state.time-- <= 0) {
                state.time = 60;
                state.smsSendBtn = false;
                window.clearInterval(interval);
              }
            }, 1000);

            const hide = $message.loading('验证码发送中..', 0);
            sipSms(values.username, 2)
                    .then((res) => {
                      if (res.success) {
                        setTimeout(hide, 2500);
                        this.$notification['success']({
                          message: '提示',
                          description: '验证码获取成功',
                          duration: 8
                        });
                      } else {
                        setTimeout(hide, 2500);
                        this.$notification['error']({
                          message: '错误',
                          description: res.msg,
                          duration: 4
                        });
                      }
                    })
                    .catch((err) => {
                      setTimeout(hide, 1);
                      clearInterval(interval);
                      state.time = 60;
                      state.smsSendBtn = false;
                      this.$notification['error']({
                        message: '错误',
                        description: err.message,
                        duration: 4
                      });
                    });
          }
        });
      },
      requestFailed(err) {
        this.$notification['error']({
          message: '错误',
          description: err.msg,
          duration: 4
        });
      },

      handleStoreInsert(token) {
        axios({
          method: 'post',
          url: '/api/shop/insert',
          headers: { Authorization: token },
          data: {
            code: this.$route.query.refresh_token,
            type: 'PDD'
          }
        })
                .then((res) => {
                  if (res.data.success) {
                    this.$message.success(res.data.msg);
                    window.location.href = '/personal/storeBind';
                  } else {
                    this.$message.error(res.data.msg);
                  }
                  setTimeout(() => {
                    window.close();
                  }, 500);
                })
                .catch((err) => {
                  this.$message.error('出错啦～');
                });
      }
    }
  };
</script>

<style lang="less" scoped>
  #userLayout.user-layout-wrapper {
    height: 100%;

    &.mobile {
      .container {
        .main {
          max-width: 368px;
          width: 98%;
        }
      }
    }

    .container {
      width: 100%;
      min-height: 100%;
      background: #f0f2f5 url(~@/assets/background.svg) no-repeat 50%;
      background-size: 100%;
      padding: 110px 0 144px;
      position: relative;

      a {
        text-decoration: none;
      }

      .top {
        text-align: center;

        .header {
          height: 44px;
          line-height: 44px;

          .badge {
            position: absolute;
            display: inline-block;
            line-height: 1;
            vertical-align: middle;
            margin-left: -12px;
            margin-top: -10px;
            opacity: 0.8;
          }

          .logo {
            height: 44px;
            vertical-align: top;
            margin-right: 16px;
            border-style: none;
          }

          .title {
            font-size: 33px;
            color: rgba(0, 0, 0, 0.85);
            font-family: Avenir, 'Helvetica Neue', Arial, Helvetica, sans-serif;
            font-weight: 600;
            position: relative;
            top: 2px;
          }
        }
        .desc {
          font-size: 14px;
          color: rgba(0, 0, 0, 0.45);
          margin-top: 12px;
          margin-bottom: 40px;
        }
      }

      .main {
        min-width: 260px;
        width: 368px;
        margin: 0 auto;
      }

      .footer {
        position: absolute;
        width: 100%;
        bottom: 0;
        padding: 0 16px;
        margin: 48px 0 24px;
        text-align: center;

        .links {
          margin-bottom: 8px;
          font-size: 14px;
          a {
            color: rgba(0, 0, 0, 0.45);
            transition: all 0.3s;
            &:not(:last-child) {
              margin-right: 40px;
            }
          }
        }
        .copyright {
          color: rgba(0, 0, 0, 0.45);
          font-size: 14px;
        }
      }
    }
  }
  .user-layout-login {
    label {
      font-size: 14px;
    }

    .getCaptcha {
      display: block;
      width: 100%;
      height: 40px;
    }

    .forge-password {
      font-size: 14px;
    }

    button.login-button {
      padding: 0 15px;
      font-size: 16px;
      height: 40px;
      width: 100%;
    }

    .user-login-other {
      text-align: left;
      margin-top: 24px;
      line-height: 22px;

      .item-icon {
        font-size: 24px;
        color: rgba(0, 0, 0, 0.2);
        margin-left: 16px;
        vertical-align: middle;
        cursor: pointer;
        transition: color 0.3s;

        &:hover {
          color: #1890ff;
        }
      }

      .register {
        float: right;
      }
    }
  }
</style>
